<template>
  <BaseList
    :list-query="languages"
    route="language"
    locale-section="pages.languages"
  />
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "LanguagesList",
  components: {
    BaseList: () => import("@/components/BaseList")
  },
  data() {
    return {
      languages: gql`
        query languages {
          list: languages {
            id
            name: language
            language
            sv
            en
          }
        }
      `
    };
  }
};
</script>
